import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { estilosProv } from "../../configProvincias";
import $ from "jquery";

const LlamadorJuego = ({
  enableJuego,
  clickJuego,
  cantCuponesJuego,
  logoJuego,
  nombreJuego,
  promocion,
  estadoPlataforma,
  urljuego,
  propSide,
  tieneCuil,
  size,
  isNavBar,
}) => {
  const codOrg = process.env.REACT_APP_COD_ORGANIZACION;
  const fondoLlamador = estilosProv[codOrg].fondoLlamador;
  const fondo = estilosProv[codOrg].fondo;
  const gradiente1Llamador = estilosProv[codOrg].gradiente1Llamador;
  const gradiente2Llamador = estilosProv[codOrg].gradiente2Llamador;

  const testAnim = () => {
    let b = document.getElementById("newGameModal");
    if (b !== null) {
      if (b.className.includes("modal fade show")) {
        $(".ripple").click();
      }
    }
  };

  const linkAJuego = () => {
    if (
      [
        "quinielatradicional",
        "quinielainstantanea",
        "tombola",
        "quiniexpress",
        "tomboexpress",
      ].includes(urljuego.toLowerCase())
    ) {
      return "/juego/" + urljuego;
    }
    if (
      [
        "patagoniaminibingo",
        "patagoniatelebingo",
        "pozodelaquiniela",
        "loteriaunificada",
        "raspadita_money",
      ].includes(urljuego)
    ) {
      if (window.getCuil()) {
        return "/juego/" + urljuego;
      }
    }
  };

  return (
    enableJuego === "true" &&
    (estadoPlataforma.acceptedTyc === true ||
      estadoPlataforma.acceptedTyc === null ||
      estadoPlataforma.acceptedTyc === undefined) &&
    (estadoPlataforma.autoexcluidoHasta === undefined ||
      estadoPlataforma.autoexcluidoHasta === null) &&
    (isNavBar === false ? (
      <DivIconoLlamadorJuego className="col">
        <Animated
          animationIn="flipInY"
          style={{ cursor: "pointer" }}
          animationInDuration={1200}
        >
          <Animated
            animationIn="flipInY"
            animationInDuration={1200}
            isVisible={true}
          >
            <ContainerJuegos
              className="container-fluid"
              onClick={() => {
                testAnim();
                window.generarEvento(
                  "Selección " + nombreJuego,
                  "Ir a juego " + nombreJuego,
                  ""
                );
                if (urljuego === "patagoniaminibingo") {
                  clickJuego("20");
                } else if (urljuego === "patagoniatelebingo") {
                  clickJuego("21");
                } else {
                  clickJuego();
                }
                let b = document.getElementById("newGameModalDesktop");
                if (b !== null) {
                  if (b.className.includes("modal fade show")) {
                    $("#BtnNewGameDesktop123").click();
                  }
                }
              }}
            >
              <Link to={linkAJuego}>
                <div className="row justify-content-center text-center">
                  <FondoLogo
                    size={size}
                    aplicarGradiente={
                      ["18", "48"].includes(codOrg) ? false : true
                    }
                    gradiente1Llamador={
                      gradiente1Llamador !== ""
                        ? urljuego.toLowerCase() === "tombola"
                          ? ["18", "48"].includes(codOrg)
                            ? "#ffffff00"
                            : "#f58634"
                          : urljuego.toLowerCase() === "tomboexpress"
                          ? ["18", "48"].includes(codOrg)
                            ? "#ffffff00"
                            : "#ed3054"
                          : gradiente1Llamador
                        : ""
                    }
                    gradiente2Llamador={
                      gradiente2Llamador !== "" ? gradiente2Llamador : ""
                    }
                    colorFondo={
                      urljuego.toLowerCase() === "tombola"
                        ? ["18", "48"].includes(codOrg)
                          ? "#ffffff00"
                          : "#f58634"
                        : urljuego.toLowerCase() === "tomboexpress"
                        ? ["18", "48"].includes(codOrg)
                          ? "#ffffff00"
                          : "#ed3054"
                        : fondoLlamador
                    }
                    className="col align-self-center"
                  >
                    {cantCuponesJuego > 0 ? (
                      <BadgeNotificacionesJuego className="position-absolute top-0 start-100 translate-middle border border-light rounded-circle">
                        {cantCuponesJuego}
                      </BadgeNotificacionesJuego>
                    ) : (
                      <></>
                    )}
                    <LogoJuego
                      size={size}
                      src={logoJuego}
                      sinFondo={["18", "48"].includes(codOrg)}
                      alt="img"
                    />
                  </FondoLogo>
                  <div className="col-12">
                    <NombreJuego>
                      {!["18", "48"].includes(codOrg) ? nombreJuego : ""}
                    </NombreJuego>
                  </div>
                  <SpanPromociones size={size}>
                    {promocion !== undefined ? promocion : <></>}
                  </SpanPromociones>
                </div>
              </Link>
            </ContainerJuegos>
          </Animated>
        </Animated>
      </DivIconoLlamadorJuego>
    ) : (
      <DivIconoLlamadorJuegoNavBar className={propSide}>
        <Animated
          animationIn="flipInY"
          style={{ cursor: "pointer" }}
          animationInDuration={1200}
        >
          <Animated
            animationIn="flipInY"
            animationInDuration={1200}
            isVisible={true}
          >
            <ContainerJuegosNavBar
              className="container"
              onClick={() => {
                console.log(urljuego);
                window.generarEvento(nombreJuego, "Ir a juego " + urljuego, "");
                testAnim();
                if (urljuego === "patagoniaminibingo") {
                  clickJuego("20");
                } else if (urljuego === "patagoniatelebingo") {
                  clickJuego("21");
                } else {
                  clickJuego();
                }
                let b = document.getElementById("newGameModalDesktop");
                if (b !== null) {
                  if (b.className.includes("modal fade show")) {
                    $("#BtnNewGameDesktop123").click();
                  }
                }
              }}
            >
              <Link to={linkAJuego}>
                <div className="row justify-content-center text-center">
                  <FondoLogoNavBar
                    size={size}
                    aplicarGradiente={
                      !["48", "18"].includes(
                        process.env.REACT_APP_COD_ORGANIZACION
                      )
                    }
                    gradiente1Llamador={
                      gradiente1Llamador !== ""
                        ? urljuego.toLowerCase() === "tombola"
                          ? ["18", "48"].includes(codOrg)
                            ? "#ffffff00"
                            : "#f58634"
                          : urljuego.toLowerCase() === "tomboexpress"
                          ? ["18", "48"].includes(codOrg)
                            ? "#ffffff00"
                            : "#ed3054"
                          : gradiente1Llamador
                        : ""
                    }
                    gradiente2Llamador={
                      gradiente2Llamador !== "" ? gradiente2Llamador : ""
                    }
                    colorFondo={
                      urljuego.toLowerCase() === "tombola"
                        ? ["18", "48"].includes(codOrg)
                          ? "#ffffff00"
                          : "#f58634"
                        : urljuego.toLowerCase() === "tomboexpress"
                        ? ["18", "48"].includes(codOrg)
                          ? "#ffffff00"
                          : "#ed3054"
                        : fondoLlamador
                    }
                    className="col align-self-center"
                  >
                    {cantCuponesJuego > 0 ? (
                      <BadgeNotificacionesJuego className="position-absolute top-0 start-100 translate-middle border border-light rounded-circle">
                        {cantCuponesJuego}
                      </BadgeNotificacionesJuego>
                    ) : (
                      <></>
                    )}
                    <LogoJuegoNavBar size={size} src={logoJuego} alt="img" />
                  </FondoLogoNavBar>
                  <div className="12">
                    <NombreJuego>{nombreJuego}</NombreJuego>
                  </div>
                  <SpanPromociones size={size}>
                    {promocion !== undefined ? promocion : <></>}
                  </SpanPromociones>
                </div>
              </Link>
            </ContainerJuegosNavBar>
          </Animated>
        </Animated>
      </DivIconoLlamadorJuegoNavBar>
    ))
  );
};

export default LlamadorJuego;

const LogoJuego = styled.img`
  width: ${(props) =>
    !props.sinFondo ? "10vh" : props.size > 991 ? "25vh" : "18vh"};
  margin-left: ${(props) => (props.sinFondo ? "-2vh" : "0vh")};
`;

const LogoJuegoNavBar = styled.img`
  width: ${(props) =>
    ["48", "18"].includes(process.env.REACT_APP_COD_ORGANIZACION)
      ? "14vh"
      : "9vh"};
  margin-top: ${(props) => (props.size <= 991 ? "0vh" : "4.5vh")};
`;

const BadgeNotificacionesJuego = styled.span`
  background-color: #5a83bd;
  border: 2px solid white;
  border-radius: 50%;
  color: white;
  z-index: 3;
  width: 4vh;
  height: 4vh;
  text-align: center;
  left: 4px;
  top: 4px;
  align-content: space-evenly;
  font-size: 2vh;
`;

const NombreJuego = styled.div`
  margin-top: 4vh;
  text-align: center;
  @media (min-width: 1920px) {
    font-size: x-large;
    font-weight: 900;
  }
`;

const rotate2 = keyframes`
0% {
    box-shadow: 8px -5px 13px 4px #b0b7b3;
  }
  50% {
    box-shadow: 8px -5px 20px 8px #b0b7b3;
  }
  100% {
    box-shadow: 8px -5px 13px 4px #b0b7b3;
  }
`;

const FondoLogo = styled.div`
  background: radial-gradient(
    circle at 65% 15%,
    ${(props) =>
      props.aplicarGradiente && props.gradiente1Llamador !== ""
        ? "white"
        : props.colorFondo}
      1px,
    ${(props) =>
      props.aplicarGradiente && props.gradiente1Llamador !== ""
        ? props.gradiente1Llamador
        : props.colorFondo}
      3%,
    ${(props) => (props.aplicarGradiente ? props.colorFondo : props.colorFondo)}
      60%,
    ${(props) =>
      props.aplicarGradiente && props.gradiente2Llamador !== ""
        ? props.gradiente2Llamador
        : props.colorFondo}
      100%
  );
  border-radius: 50%;
  align-content: space-evenly;
  height: ${(props) => (props.size <= 991 ? "15vh" : "20vh")};

  max-width: ${(props) => (props.size <= 991 ? "16vh" : "20vh")};
  ${(props) =>
    props.aplicarGradiente ? "box-shadow: 8px -5px 13px 4px #b0b7b3" : ""};
  
    &:hover {
  ${(props) =>
    props.aplicarGradiente &&
    "box-shadow: 0 0 15px 5px " + props.colorFondo + " !important;"}
    transform: scale(1.1);
`;

const FondoLogoNavBar = styled.div`
  background: radial-gradient(
    circle at 65% 15%,
    ${(props) =>
        props.aplicarGradiente && props.gradiente1Llamador !== ""
          ? "white"
          : props.colorFondo}
      1px,
    ${(props) =>
        props.aplicarGradiente && props.gradiente1Llamador
          ? props.gradiente1Llamador
          : props.colorFondo}
      3%,
    ${(props) => (props.aplicarGradiente ? props.colorFondo : props.colorFondo)}
      60%,
    ${(props) =>
        props.aplicarGradiente && props.gradiente2Llamador !== ""
          ? props.gradiente2Llamador
          : props.colorFondo}
      100%
  );
  border-radius: 50%;
  height: ${(props) => (props.size <= 991 ? "13vh" : "20vh")};
  max-width: ${(props) => (props.size <= 991 ? "13vh" : "20vh")};
  ${(props) =>
    props.aplicarGradiente ? "box-shadow: 8px -5px 13px 4px #b0b7b3" : ""}
`;

const ContainerJuegos = styled.div`
  min-width: 20vh;
  margin-top: 8vh;
`;

const ContainerJuegosNavBar = styled.div`
  min-width: 13vh;
  margin-top: 4vh;
  margin-bottom: 5vh;
`;

const SpanPromociones = styled.span`
  background-color: #01c160;
  color: #f0f0f0;
  border-radius: 1em;
  position: absolute;
  top: ${(props) => (props.size <= 991 ? "13.5vh" : "17.5vh")};
  min-width: 8rem;
  text-align: center;
`;
const DivIconoLlamadorJuego = styled.div`
  min-width: 21vh;
  margin: 0px 5px;
`;

const DivIconoLlamadorJuegoNavBar = styled.div`
  min-width: 14vh;
`;
