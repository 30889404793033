import React, { useState, useEffect } from "react";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";
import { estilosProv } from "../../configProvincias";
import MovimientosIcon from "../../img/movimientosIcon";

const BottomNavMobile = (props) => {
  const fondoSpan =
    estilosProv[process.env.REACT_APP_COD_ORGANIZACION].secundaryColor;

  const [visible, setvisible] = useState(false);
  const testAnim = () => {
    let b = document.getElementById("newGameModal");
    if (b !== null) {
      if (b.className.includes("modal fade show")) {
        $(".ripple").click();
      }
    }
    props.volverAPlataforma();
  };

  useEffect(() => {
    let b = document.getElementById("newGameModal");
    if (b !== null) {
      if (b.className.includes("modal fade show")) {
        setvisible(!visible);
      }
    }
  }, []);

  return !(
    props.height < 500 &&
    ["portrait-primary", "portrait-secondary"].includes(
      window.screen.orientation.type
    )
  ) ? (
    <>
      <BotonVerMenu
        bottom="3rem"
        type="button"
        className="btn btn-light"
        hidden={visible}
        onClick={() => setvisible(!visible)}
      >
        <span
          className="iconify help-arrow"
          data-icon="dashicons:arrow-down-alt2"
          data-inline="false"
          data-width="25px"
          color={
            [42, 12].includes(process.env.REACT_APP_COD_ORGANIZACION)
              ? "black"
              : "white"
          }
        ></span>
      </BotonVerMenu>
      <BotonVerMenu
        bottom="0rem"
        type="button"
        className="btn btn-light"
        hidden={!visible}
        onClick={() => setvisible(!visible)}
      >
        <span
          className="iconify help-arrow"
          data-icon="dashicons:arrow-up-alt2"
          data-inline="false"
          data-width="25px"
          color={
            [42, 12].includes(process.env.REACT_APP_COD_ORGANIZACION)
              ? "black"
              : "white"
          }
        ></span>
      </BotonVerMenu>
      <DivMenuRapido hidden={visible}>
        {props.logueado && (
          <div
            className={"row mx-auto p-1 text-center"}
            style={{ width: "100%" }}
          >
            {" "}
            <Link
              className={"my-icon col sm-3 pr-0 pl-0 inicioIphon "}
              exact={"true"}
              activeclassname="is-active"
              to="/home"
            >
              <div
                onClick={() => {
                  testAnim();
                }}
              >
                <span
                  className={
                    "iconify home-icon " +
                    process.env.REACT_APP_COD_ORGANIZACION
                  }
                  data-icon="bytesize:home"
                  data-inline="false"
                  data-width="35px"
                  data-height="35px"
                  color={fondoSpan}
                ></span>
              </div>
            </Link>
            <Link
              to="/consultaBilletera"
              onClick={() => {
                testAnim();
              }}
              className="col pr-0 pl-0 sm-3 billeIphon"
            >
              <div>
                <MovimientosIcon
                  color={
                    window.location.href.includes("consultaBilletera")
                      ? "#464646"
                      : fondoSpan
                  }
                  className="billetera-icon"
                  width="35px"
                  height="35px"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Movimientos"
                />
              </div>
            </Link>
            {/* <div
              className="ripple"
              data-toggle="modal"
              data-target="#newGameModal"
              hidden={props.estado !== "PLATAFORMA"}
              style={{ zIndex: "3", padding: "0 1em", minWidth: "3em" }}
            >
              <div
                className={
                  "jugar-icon-cont jugar-" +
                  process.env.REACT_APP_COD_ORGANIZACION
                }
              >
                <span
                  style={{ marginTop: "5px" }}
                  className="iconify"
                  data-icon="fluent:lottery-24-regular"
                  data-inline="false"
                  data-width="40px"
                  color= {[42,12].includes(process.env.REACT_APP_COD_ORGANIZACION) ? "black" : "white"} 
                ></span>
              </div>
            </div>*/}
            <Link
              className="my-icon col pr-0 pl-0 sm-3"
              to={
                process.env.REACT_APP_ENABLE_INGRESO_DINERO
                  ? "/identificadorQRCliente/0"
                  : "/identificadorQRCliente/"
              }
            >
              <div
                onClick={() => {
                  testAnim();
                }}
              >
                <span
                  className={
                    "iconify retirar-icon " +
                    process.env.REACT_APP_COD_ORGANIZACION
                  }
                  data-icon="ion:wallet-outline"
                  data-inline="false"
                  data-flip="horizontal"
                  data-width="35px"
                  data-height="35px"
                  color={fondoSpan}
                ></span>
              </div>
            </Link>
            <Link
              className="my-icon col pr-0 pl-0 sm-3 userIphon5"
              to="/misDatosPersonales"
            >
              <div
                onClick={() => {
                  testAnim();
                }}
              >
                <span
                  className={
                    "iconify user-icon " +
                    process.env.REACT_APP_COD_ORGANIZACION
                  }
                  data-icon="bi:person"
                  data-inline="false"
                  data-flip="horizontal"
                  data-width="35px"
                  data-height="35px"
                  color={fondoSpan}
                ></span>
              </div>{" "}
            </Link>
          </div>
        )}
      </DivMenuRapido>
    </>
  ) : (
    <></>
  );
};

export default BottomNavMobile;

const BotonVerMenu = styled.button`
  bottom: ${(props) => props.bottom};
  position: fixed;
  z-index: 1;
  background-color: rgb(227, 229, 231);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  left: 0;
`;

const DivMenuRapido = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  box-shadow: 0px 0 10px rgb(146 146 146 / 80%);
  @media (min-width: 1920px) {
    // height: 50vh;
  }
`;
