import React from "react";
import axios from "axios";
import { withRouter, Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { data } from "jquery";
import { useState, useEffect } from "react";
import $ from "jquery";

const CarrouselPublicidad = ({
  viewTrivia,
  setviewTrivia,
  documento,
  token,
}) => {
  const alertaYaJugo = () => {
    $.confirm({
      title: '<b className="op-text">Trivia</b>',
      backgroundDismiss: true,
      columnClass: "medium",
      animation: "zoom",
      closeIcon: true,
      closeAnimation: "scale",
      content: `<h5 className="text-center mt-4 mb-2">
          Ya participó en TRIVIA MUNDIAL
					 </h5>`,
      buttons: {
        Si: {
          text: "Aceptar",
          action: function () {},
        },
      },
    });
  };

  const handleClick = () => {
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_INIT_PARTICIPANDO_TRIVIA +
      "=" +
      documento;

    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.data === true) {
          alertaYaJugo();
        } else {
          setviewTrivia(false);
        }
      });
  };

  return (
    <Carousel
      showIndicators={false}
      showStatus={false}
      showArrows={false}
      autoPlay={true}
      infiniteLoop={true}
      interval={4200}
      transitionTime={1000}
    >
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <img
          src={
            require("./img/PUBLI_1_ORG_" +
              process.env.REACT_APP_COD_ORGANIZACION +
              ".png").default
          }
        />
      </div>
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <img
          src={
            require("./img/PUBLI_2_ORG_" +
              process.env.REACT_APP_COD_ORGANIZACION +
              ".png").default
          }
        />
      </div>

      {/* <div style={{cursor:"pointer", backgroundColor:"lightgrey"}} data-toggle="modal" data-target="#rateModal"><img src={require("./img/PUBLI_2_ORG_"+process.env.REACT_APP_COD_ORGANIZACION+".png").default} className="publicidadImg"/></div> */}
    </Carousel>
  );
};

export default withRouter(CarrouselPublicidad);
