import React from "react";

class JuegoResponsable extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div className="p-1 mx-auto">
        <h5 className="text-center mb-3">
          Jugar es solamente para divertirte!, no transformes el juego en un
          problema!
        </h5>

        <p className="text-small text-justify">
          <ul>
            <li>
              Decidí de antemano cuánto dinero estás dispuesto a gastar que no
              afecte drásticamente tu presupuesto.
            </li>
            <li>
              No trates de recuperar lo perdido jugando más, porque podría
              simplemente incrementar tus pérdidas.
            </li>
            <li>No utilices el juego para modificar tu estado de ánimo.</li>
            <li>
              Tomá conciencia de qué modo juegas y el tiempo que llevas jugando.
            </li>
            <li>
              Aún si estás ganando, trata de tomar descansos ocasionales y pensá
              cuál es el mejor momento para terminar tu juego.
            </li>
            <li>
              Recordá que las apuestas no son un medio para convertirte en rico
              rápidamente y poder saldar tus deudas.
            </li>
            <li>
              Las apuestas son juegos de azar. No hay sistemas o fórmulas para
              garantizar el éxito.
            </li>
          </ul>
        </p>

        <h5 className="text-center mb-3 mt-3">TEST DE JUEGO COMPULSIVO</h5>

        <ul>
          <li>¿Juegas para evitar las preocupaciones?</li>
          <li>
            ¿Juegas para tratar de conseguir dinero y resolver tus dificultades
            económicas?
          </li>
          <li>¿Buscas distintas formas de conseguir dinero para jugar?</li>
          <li>¿No podes controlar cómo y cuándo detener el juego?</li>
          <li>
            ¿Generalmente jugas hasta que se gasta el último peso de tu
            bolsillo?
          </li>
          <li>
            ¿Sentís que engañas a tu familia, a tus amigos para ocultar el grado
            de adicción al juego?
          </li>
          <li>
            ¿Desatendés a tu familia y tu trabajo, poniendo en riesgo tus
            oportunidades profesionales?
          </li>
          <li>
            ¿Amenazas los recursos económicos familiares, al punto que vendes
            tus pertenencias y las de tu familia?
          </li>
          <li>¿Incursionas en actividades ilegales para conseguir dinero?</li>
        </ul>

        <p className="text-small text-justify">
          Todas estas conductas son indicios de problemas de juego compulsivo.
          Si consideras que presentas algún problema de juego compulsivo, no
          dudes en contactarte con ENREJA y su Programa de Adicción al Juego al{" "}
          <span className="m-bold">0-800-55-58346</span> o ingresa en
          <a href="http://www.enreja.gov.ar/programa-de-prevencion-a-la-adiccion-al-juego/">
            {" "}
            www.enreja.gov.ar/programa-de-prevencion-a-la-adiccion-al-juego/
          </a>{" "}
          . Tu solicitud será tratada con absoluta confidencialidad.
        </p>
      </div>
    );
  }
}

export default JuegoResponsable;
