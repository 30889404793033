import React from "react";

const TerminosCondiciones = () => {
  return (
    <div className="p-1 mx-auto">
      <h4 className="text-center mb-3">TÉRMINOS Y CONDICIONES</h4>

      <p className="text-small text-justify">
        <span className="m-bold">Loteria de Salta Online </span>es una
        plataforma online o sitio web de juegos de azar, perteneciente a Tecno
        Acción Salta S.A debidamente autorizado y aprobado por el EN.RE.J.A,
        autoridad de Aplicación de Juegos de Azar de la Provincia de Salta
        <br></br>
        Al utilizar la plataforma de juego o sitio WEB, el usuario reconoce y
        acepta que:
        <br></br>
        <ul style={{ paddingLeft: "25px" }} className="mt-2 mb-2">
          <li>
            Ha leído, comprendido y aceptado estos Términos y Condiciones. Que
            estos Términos y Condiciones constituyen un acuerdo legalmente
            vinculante entre el usuario y{" "}
            <span className="m-bold">Loteria de Salta Online</span> sobre el uso
            de la plataforma de juego o sitio web.
          </li>
          <li>
            Que todas las apuestas serán aceptadas únicamente conforme a estos
            términos y condiciones, y serán la confirmación de que el
            participante en las apuestas conoce estas reglas y está totalmente
            de acuerdo con las mismas.
          </li>
          <li>
            Las previsiones que conforman este reglamento o sus anexos se
            aplicaran por igual a todas las apuestas realizadas por internet,
            cualquiera sea el medio y/o dispositivo utilizado, incluidas
            aplicaciones descargables para dispositivos móviles y sin distinción
            del juego a que tales apuestas refieran, entendiendo que las
            referencias al uso de la página web lo son también al uso de las
            apuestas por internet y/o por medio de aplicaciones para
            dispositivos móviles.
          </li>
        </ul>
        El usuario asume voluntariamente los riesgos propios de la participación
        en apuestas a juegos de azar y de la utilización de la plataforma de
        juego o sitio web, conociendo que se hallan a su disposición la mesa de
        ayuda.
      </p>

      <h5 className="text-center mb-3 mt-3">CONDICIONES DE USO</h5>
      <p className="text-small text-justify">
        Como condición del uso de la plataforma de juego o sitio web, el usuario
        garantiza y asume la responsabilidad de que no utilizará ni accederá a
        la misma, a sus Servicios, a el Software y/o a la Información para un
        propósito que sea ilícito bajo cualquier legislación que sea aplicable o
        que esté prohibido y/o incumpla estos Términos y condiciones.
        <br></br>
        En particular el usuario garantiza, comprometiéndose a ello como
        condición necesaria y excluyente para el uso de la plataforma de juego o
        sitio web:
        <ul style={{ paddingLeft: "25px" }} className="mt-2 mb-2">
          <li>Qué actúa en representación propia.</li>
          <li>Qué sus capacidades legales no están limitadas.</li>
          <li>
            Qué no se le ha diagnosticado ni ha sido clasificado como ludópata.
          </li>
          <li>Qué tiene más de 18 años.</li>
          <li>
            Qué es plenamente consciente del riesgo de pérdida de dinero en el
            transcurso del uso de los Servicios.
          </li>
          <li>
            Qué no está depositando dinero procedente de actividades delictivas
            u otras actividades ilegales o no autorizadas.
          </li>
          <li>
            Qué no está realizando actividades delictivas, ilícitas ni no
            autorizadas ni planea utilizar su cuenta abierta con nosotros en
            conexión con dicho tipo de actividades y que no utilizará ni
            permitirá que otras personas utilicen la plataforma de juego, ni su
            cuenta de apuesta para actividades delictivas o ilícitas entre las
            que se incluyen, pero no se limitan a, blanqueo de dinero, bajo
            cualquier ley aplicable a usted o a nosotros.
          </li>
          <li>
            Qué mantendrá su nombre de usuario, su número de cuenta y su
            contraseña en confidencialidad y protegidos contra acceso o uso no
            autorizados y para ello deberá cambiar su contraseña inmediatamente
            o avisarnos inmediatamente en caso de violación de la
            confidencialidad de su nombre de usuario, contraseña,{" "}
            <span className="m-bold">Loteria de Salta Online</span> no se
            responsabiliza si terceros acceden a su cuenta.
          </li>
          <li>
            Qué el usuario será el único responsable de todas las actividades
            que tengan lugar durante el acceso y el uso de la plataforma de
            juego y/o los Dispositivos bajo su nombre de usuario, su número de
            cuenta, con independencia de si dicho acceso y/o uso ha sido
            autorizado por usted o con su consentimiento.
          </li>
          <li>
            Qué no utilizará la plataforma de juego, los Sitios Web, los
            Dispositivos, el Software o la Información de cualquier modo que
            interfiera o pueda interferir con la disponibilidad de la plataforma
            de juego Servicios y los Sitios Web para otros usuarios y no hacer
            nada que dificulte o pueda dificultar el funcionamiento operativo de
            los Servicios y los Sitios Web; no solicitar ni intentar de ningún
            modo obtener información relativa a otros usuarios; que su acceso a
            la plataforma de juego y la Información en y a través de los Sitios
            Web y/o los Dispositivos no es ilegal ni está prohibido por leyes
            aplicables a usted ni obligaciones contractuales aplicables a usted
            personalmente.
          </li>
        </ul>
        <span className="m-bold">Loteria de Salta Online</span> no será ni se
        hará responsable de ninguna falla o problema que surja debido al equipo
        informático de un cliente, su conexión a internet o por la provisión del
        servicio de telecomunicaciones, incluido a los que impidan al cliente
        realizar apuestas, o ver o recibir cierta información en relación a
        eventos en particular.
      </p>

      <h5 className="text-center mb-3 mt-3">
        REALIZACIÓN DE APUESTAS Y PROCEDIMIENTO DE ACEPTACIÖN DE APUESTAS
      </h5>
      <h6 className="text-center mb-3 mt-3">Registro de usuario</h6>
      <p className="text-small text-justify">
        Para realizar apuestas por dinero y participar en las ofertas de juego
        que ofrece <span className="m-bold">Loteria de Salta Online</span>, es
        indispensable completar el proceso de Registración como usuario de la
        plataforma o sitio web.
        <br></br>
        Al registrarse se abrirá automáticamente a nombre del usuario una cuenta
        gratuita y sin costo alguno. En dicha cuenta se reflejarán todos los
        movimientos monetarios que realiza el usuario en la plataforma.
        <br></br>
        Para registrarse, el cliente deberá complementar correctamente bajo fe
        de juramento y en su totalidad el formulario de inscripción
        preestablecido.
        <br></br>
        Es responsabilidad del usuario brindar datos veraces, reales y
        correctos. <span className="mbold">Loteria de Salta Online</span> no se
        responsabiliza en caso de que el usuario brinde información errónea.
        Como medida de prevención,{" "}
        <span className="m-bold">Loteria de Salta Online</span> puede solicitar
        al usuario identificación personal (documento de identidad, pasaporte),
        a fin de verificar que la información que el usuario presentó en el
        momento de la suscripción es veraz. El no cumplimiento de esta cláusula
        podrá implicar la, denegación de su inscripción, congelación de su
        cuenta y/o baja del servicio.
        <br></br>
        El usuario se compromete a presentar, toda la documentación e
        información necesaria para que{" "}
        <span className="m-bold">Loteria de Salta Online</span> confirme la
        veracidad de los datos por usted registrados, en caso de que{" "}
        <span className="m-bold">Loteria de Salta Online</span> lo solicite. Si
        la información personal brindada por el usuario no es correcta,{" "}
        <span className="m-bold">Loteria de Salta Online</span> bloqueará la
        cuenta y retendrá el balance de esta (sin derecho a indemnización alguna
        o intereses para el usuario) hasta que el usuario presente la
        documentación o información solicitada. El usuario es responsable de
        mantener su información personal (e-mail, dirección, número telefónico,
        etc.) actualizado y cualquier cambio en la información deberá ser de
        forma inmediata.
        <br></br>
        Toda la información proporcionada por el usuario se tratará de forma
        segura y en estricta conformidad con las leyes pertinentes de protección
        de datos. El usuario puede actualizar su información personal en
        cualquier momento en la sección Accesos /Datos Personales del sitio Web
        o bien llamando por teléfono al equipo de atención al cliente.
        <br></br>
        Los siguientes sujetos no podrán ser usuarios:
        <ul style={{ paddingLeft: "25px" }} className="mt-2 mb-2">
          <li>Personas Jurídicas.</li>
          <li>
            Aquellas personas participantes de los eventos y/o sorteos en los
            cuales están colocadas las apuestas (personal de la Lotería o,
            Titulares de Agencia)
          </li>
          <li>Personas incompetentes.</li>
          <li>Menores de 18 años.</li>
          <li>
            Alcanzados por exclusión judicial y/o autoexclusión y/o auto
            restricción, con relación a las apuestas a juegos de azar hasta su
            rehabilitación judicial, informe profesional de rehabilitación o
            cumplimiento del plazo de restricción.
          </li>
          <li>
            No estar comprendido en ninguna cláusula prohibitiva del reglamento
            y/o normas que la complementen.
          </li>
        </ul>
        A los usuarios que ya se encuentren registrados les estará prohibido
        inscribirse como nuevos clientes / usuarios indicando otro nombre u otra
        dirección de e-mail. Si{" "}
        <span className="m-bold">Loteria de Salta Online</span> detecta alguna
        conexión entre cuentas de usuario, asumirá la existencia de registros
        múltiples y, por consiguiente, lo considerará como una infracción de los
        presentes Términos y Condiciones. En tales casos,{" "}
        <span className="m-bold">Loteria de Salta Online</span> está autorizado
        a anular cualquier premio o ganancia transferida a una cuenta de
        usuario, así como a invalidar cualquier premio obtenido. La sospecha de
        posesión de varias cuentas sólo se puede contrarrestar si el usuario es
        capaz de demostrar sin dudas que los titulares de las cuentas son
        personas diferentes y que ha cumplido con todas las condiciones
        expuestas en estos Términos y Condiciones.
        <br></br>
        Las apuestas serán realizadas de manera válida si su nombre de usuario y
        contraseña han sido introducidos correctamente, sujetas siempre a que
        haya fondos disponibles suficientes en su cuenta.
        <br></br>
        Es exclusiva responsabilidad suya realizar correctamente las apuestas,
        una vez que sus apuestas hayan sido realizadas y se hayan confirmado, no
        pueden ser canceladas, ni ser modificadas y se considerarán pruebas
        concluyentes de las apuestas que usted ha realizado.
        <br></br>
        Tenga en cuenta que todas las apuestas son registradas en la base de
        datos del registro de transacción. Los registros de transacción son
        pruebas concluyentes de todas las transacciones y de la hora a la que se
        realizaron dichas transacciones.
        <br></br>
        <span className="m-bold">Loteria de Salta Online</span> no se
        responsabiliza por el mal funcionamiento de los dispositivos
        informáticos o de comunicación utilizados para acceder a la plataforma.
        <br></br>
        Las apuestas estarán todas sujetas a las correspondientes normas de
        apuesta aplicables a cada juego y a estos Términos y condiciones.{" "}
        <span className="m-bold">
          En caso de mal funcionamiento del sistema de la plataforma de juego de
          Loteria de Salta Online, este se reserva el derecho a invalidar
          cualquiera o todas las apuestas realizadas.
        </span>
        <br></br>
        Los usuarios solo podrán realizar apuestas por internet mediante los
        dispositivos permitidos y a los juegos autorizados, debiendo utilizar
        solo la plataforma o sitio web{" "}
        <span className="m-bold">Loteria de Salta Online.</span>
        <br></br>
        Todas las apuestas son realizadas a entera discreción y riesgo del
        usuario. Solo serán aceptadas aquellas apuestas realizadas a través de
        internet.
        <br></br>
        Es responsabilidad del usuario comprobar que los datos de sus apuestas
        sean los correctos, ya que una vez realizadas no podrán ser canceladas
        ni modificadas.
        <br></br>
        Cada apuesta válida recibirá un código único de transacción y{" "}
        <span className="m-bold">Loteria de Salta Online</span> no se hace
        responsable de la determinación de cualquier apuesta que no se realice
        con un código único de transacción.
        <br></br>
        En caso de duda acerca de la validez de una apuesta, se recomienda a los
        usuarios verificar el historial de apuestas, o bien ponerse en contacto
        con la mesa de ayuda.
        <br></br>
        Si existieren desacuerdos, tanto del usuario como de parte de{" "}
        <span className="m-bold">Loteria de Salta Online</span>, convienen en
        aceptar como definitivo el registro de transacciones que surja de la
        base de datos del sistema.
      </p>

      <h6 className="text-center mb-3 mt-3">
        Créditos: su carga y utilización
      </h6>

      <p className="text-small text-justify">
        El usuario, una vez registrado en cualquier momento podrá acceder a la
        carga de créditos, apostar y acreditar sus ganancias.
        <br></br>
        Todos los premios obtenidos por los usuarios registrados a partir de
        apuestas válidas serán acreditados automáticamente a las
        correspondientes cuentas de cada uno de los apostadores luego de
        resueltos los sorteos. Por lo tanto, a partir de este mecanismo todos
        los premios son pagados a cada apostador que realizó la apuesta, no
        existiendo premios caducos.
      </p>

      <h6 className="text-center mb-3 mt-3">Acceso al Historial del Cliente</h6>

      <p className="text-small text-justify">
        Los clientes pueden acceder fácilmente a un historial de las últimas
        transacciones, apuestas, retiros o depósitos que se han realizado en la
        plataforma. El saldo de un cliente estará siempre disponible en la
        sección correspondiente y puede verse una vez que ha ingresado a su
        cuenta.
      </p>

      <h6 className="text-center mb-3 mt-3">Cierre de Cuentas</h6>

      <p className="text-small text-justify">
        <span className="m-bold">Loteria de Salta Online</span> se reserva el
        derecho de admisión de cualquier solicitud de registro, de cualquier
        persona sin importar si este formulario alguna vez fue aceptado, sin más
        aviso que la comunicación por medio de correo electrónico consignado por
        el cliente en el formulario de registro y/o por la mera denegación del
        acceso a la plataforma o sitio web.
        <br></br>
        Ante cualquier conducta indebida, como ser: insultos, amenazas,
        difamación en medios de comunicación, redes sociales, y/o cualquier
        medio que provoque un daño en la imagen de{" "}
        <span className="m-bold">Loteria de Salta Online</span> por parte de los
        usuarios, provocará el cierre de la cuenta{" "}
        <span className="m-bold">Loteria de Salta Online</span> se reserva el
        derecho a defender legalmente sus intereses.
        <br></br>
        Es requisito indispensable, que los clientes mantengan el buen trato y
        decoro para con el sitio, y personal disponible en la atención al
        cliente. Caso contrario, el área gerencial determinara el cierre
        definitivo de la cuenta.
        <br></br>
        Así mismo, es obligación de{" "}
        <span className="m-bold">Loteria de Salta Online</span> mantener el
        mismo trato para con los clientes, esforzándose en evacuar cualquier
        tipo de reclamo y/o sugerencias sin distinción alguna, apuntando a la
        celeridad y buen servicio.
        <br></br>
        <span className="m-bold">Loteria de Salta Online</span>, se reserva el
        derecho de admisión de fondos si sus fuentes son de orígenes dudosos o
        injustificables, por lo que ocasionara que el equipo de auditoría
        realice el control pertinente sobre la cuenta del usuario, con el
        seguimiento constante de la conducta del usuario. El usuario acepta y
        permite los controles de Loteria de Salta Online para la prevención de
        lavado de activos y/o actividades ilícitas y/o contrarias a los términos
        y condiciones de la plataforma de juego.
      </p>

      <h6 className="text-center mb-3 mt-3">Alerta de Usuarios</h6>

      <p className="text-small text-justify">
        <span className="m-bold">Loteria de Salta Online</span> cuenta con un
        equipo de trabajo que se encuentran plenamente capacitado para detectar
        y actuar en forma inmediata ante la detección de potenciales agentes
        considerados de riesgos para nuestra empresa, tales como:
        <ul style={{ paddingLeft: "25px" }} className="mt-2 mb-2">
          <li>Menores de Edad</li>
          <li>Ludópatas</li>
          <li>Transacciones Fraudulentas</li>
          <li>Lavado de Activos.</li>
        </ul>
        <span className="m-bold">
          Loteria de Salta Online, se reserva el derecho de admisión de fondos
          si sus fuentes son de orígenes dudosos o injustificables, por lo que
          ocasionara que el equipo de auditoría realice el control pertinente
          sobre la cuenta del usuario, con el seguimiento constante de la
          conducta del usuario. El usuario acepta y permite los controles de
          Loteria de Salta Online para la prevención de lavado de activos y/o
          actividades ilícitas y/o contrarias a los términos y condiciones de la
          plataforma de juego.
        </span>
      </p>

      <h6 className="text-center mb-3 mt-3">El Juego y los menores de edad</h6>

      <p className="text-small text-justify">
        La ley prohíbe a los menores de dieciocho (18) años jugar por dinero y{" "}
        <span className="m-bold">Loteria de Salta Online</span> considera muy
        seriamente esta responsabilidad. En el supuesto de lograr identificar un
        menor de edad registrado, apostando en nuestro sitio online,
        inmediatamente procederemos a comunicarnos con el menor, solicitando más
        información sobre sus Tutores a los efectos de comunicar esta situación,
        bloquearemos su cuenta, y el mismo perderá todo derecho sobre las
        transacciones realizadas. Además, se informará inmediatamente a la
        autoridad de control.
      </p>

      <h6 className="text-center mb-3 mt-3">
        Unidad monetaria – Limitaciones financieras
      </h6>

      <p className="text-small text-justify">
        La plataforma acepta como unidad monetaria al Peso argentino ($AR) y se
        reserva el derecho a suprimir la realización de apuestas en cualquier
        otra moneda.
        <br></br>
        <span className="m-bold">Loteria de Salta Online</span> se reserva el
        derecho de limitar la apuesta máxima, como así también cambiar los
        límites de apuestas para clientes en particular sin previo aviso,
        encontrándonos plenamente facultados para decidir el curso de los
        límites. Las limitaciones o exclusiones a clientes de la plataforma, no
        dará derecho a reclamo alguno por parte del usuario, habiendo éste
        reconocido que la plataforma hace uso del derecho de admisión y
        permanencia.
      </p>

      <h6 className="text-center mb-3 mt-3">Fondos</h6>

      <p className="text-small text-justify">
        Los fondos ingresados por los usuarios en sus cuentas se encuentran
        protegidos. Contamos con cuentas exclusivas para los apostadores, no
        pudiendo <span className="m-bold">Loteria de Salta Online</span>{" "}
        disponer de dichos fondos. De esta forma otorgamos confiabilidad en las
        transacciones.
      </p>

      <h6 className="text-center mb-3 mt-3">Juego responsable</h6>

      <p className="text-small text-justify">
        El usuario cuenta con la línea 0800-555-8346 o 387-155420599. Programa
        de Juego Responsable – En.Re.J.A de atención gratuita y confidencial
        cuyos principales objetivos son:
        <ul style={{ paddingLeft: "25px" }} className="mt-2 mb-2">
          <li>
            Brindar información y orientación sobre la problemática de la
            adicción al juego a; usuarios, familiares y comunidad en general a
            través de la atención
          </li>
          <li>
            Realizar la derivación asistida del usuario, previo consentimiento
            de este.
          </li>
          <li>Efectuar el seguimiento de los usuarios en tratamiento</li>
        </ul>
      </p>

      <h6 className="text-center mb-3 mt-3">Suspensión de pagos</h6>

      <p className="text-small text-justify">
        <span className="m-bold">Loteria de Salta Online</span> se reserva el
        derecho, a su exclusivo criterio, a declarar nulas las ganancias y a
        decomisar el saldo de su cuenta de apuesta, y suspender la prestación de
        los Servicios/desactivar su cuenta si{" "}
        <span className="m-bold">Loteria de Salta Online</span> dispone de
        indicios suficientes para creer o comprueba lo siguiente:
        <ul style={{ paddingLeft: "25px" }} className="mt-2 mb-2">
          <li>
            Usted tiene más de una cuenta activa con{" "}
            <span className="m-bold">Loteria de Salta Online</span>
          </li>
          <li>Proporciona información de registro incorrecta o engañosa;</li>
          <li>
            No ha suministrado o se ha negado a suministrar la información de
            identificación solicitada;
          </li>
          <li>No ha cumplido la Mayoría de edad;</li>
          <li>
            Ha sido descubierto estafando o tratando de estafar o se ha
            descubierto que ha estafado a alguien.
          </li>
          <li>
            Si <span className="m-bold">Loteria de Salta Online</span>. ha
            determinado que usted ha empleado o hecho uso de un sistema de
            inteligencia artificial o de otra naturaleza (incluidas máquinas,
            ordenadores, software u otros sistemas automatizados)
            específicamente diseñados para anular el sistema para defraudar a{" "}
            <span className="m-bold">Loteria de Salta Online</span>.
          </li>
          <li>
            Ha permitido (intencional o inintencionadamente) que otra persona
            usara su cuenta;
          </li>
          <li>Incumple alguno de estos Términos y condiciones;</li>
          <li>
            Usted ha reconocido que está, o nosotros creemos razonablemente que
            esté sufriendo de un problema relacionado con la ludopatía;
          </li>
          <li>
            Autoridad judicial, policial u cualquier otra autoridad o entidad
            pública o privada lo requiera.
          </li>
        </ul>
        Reservamos el derecho de retener el pago o cantidad ganadora, bloquear
        depósitos o transacciones de apuestas hasta que la identidad del ganador
        estuviese verificado a nuestra satisfacción para asegurar que el pago de
        la cantidad ganadora se hiciera a la persona correcta y para cumplir con
        los requisitos de la Unidad de Información Financiera al verificar la
        identidad del usuario.
        <br />
        Nos reservamos el derecho, sin responsabilidad con ninguna parte, a
        nuestro exclusivo criterio y sin el deber de notificárselo, a añadir
        nuevos juegos o funciones a la plataforma o sitio web, a comenzar,
        cesar, suspender, restringir el acceso o modificar cualquier juego o
        función en cualquier momento, previa autorización del En.Re.J.A.
      </p>

      <h6 className="text-center mb-3 mt-3">Puntos de venta</h6>

      <p className="text-small text-justify">
        Solamente la red de venta de Lotería de Salta podrá ser autorizadas a
        intervenir en la venta de créditos y pago de premios al usuario que se
        relacione al sistema de apuestas por internet implementado por el
        presente reglamento.
        <br></br>
        La red de venta será fehacientemente informada vía circular de toda
        modificación que afecte el presente reglamento o la modalidad de las
        apuestas por internet.
      </p>

      <h6 className="text-center mb-3 mt-3">Licencia de software</h6>

      <p className="text-small text-justify">
        Por el presente, reconoce y acepta que el servicio que se pone a su
        disposición para la utilización de la plataforma de juego es propiedad
        de <span className="m-bold">Tecno Acción Salta S.A</span>, y que el
        usuario no obtiene ningún derecho sobre dicho servicio.
      </p>

      <h6 className="text-center mb-3 mt-3">Dispositivos Permitidos</h6>

      <p className="text-small text-justify">
        Quedan permitidos para la formalización de apuestas por internet en la
        plataforma o sitio web, las computadoras y/o ordenadores personales y/o
        teléfonos celulares inteligentes y/u otro dispositivo o medio de
        comunicación a distancia que pueda conectarse a internet por medio de un
        browser compatible con el sistema.
        <br></br>
        La tecnología de tales dispositivos deberá permitir, además de la
        realización de apuestas y seguimiento del juego, el registro e
        identificación del usuario y el correspondiente control de estadísticas,
        cuentas, movimientos, operaciones y/o transacciones de quien los
        utilice. <span className="m-bold">Loteria de Salta Online</span> no se
        hace responsable por las limitaciones, errores o fallas de los
        dispositivos elegidos por el usuario.
      </p>

      <h6 className="text-center mb-3 mt-3">
        Premios Mayores – Procedimiento según Normativas Unidad de Información
        Financiera
      </h6>

      <p className="text-small text-justify">
        En el caso en que un apostador sea beneficiario de premios mayores, que
        superen los Pesos Ciento Cuarenta Mil ($140.000) fijado por la Unidad de
        Información Financiera (UIF), en la Resolución 199/11, Resolución
        70/2011 y sus modificatorias, los fondos serán inmovilizados hasta tanto
        el usuario cumpla con lo establecido por dicho Organismo, en el marco de
        las medidas de prevención de lavados de activos y financiación de
        terrorismo, exigidas a los sujetos obligados de la industria del juego
        de azar.
        <br></br>
        Esta situación generará una notificación al cliente, informando los
        pasos que debe seguir para cumplir con el procedimiento, el que
        consistirá en completar y firmar de puño y letra el formulario de
        “Declaración Jurada de Datos Personales” y el formulario “Declaración
        Jurada Sobre la Condición de Persona Expuesta Políticamente (PEP)”.
        <br></br>
        Ambos formularios podrá firmarlos en su agencia amiga o en instalaciones
        de Lotería de Salta. Los fondos serán liberados una vez que el operador
        verifique la recepción de ambos formularios completos y debidamente
        firmados.
      </p>

      <h6 className="text-center mb-3 mt-3">
        Violaciones de estos Términos y condiciones
      </h6>

      <p className="text-small text-justify">
        <span className="m-bold">Loteria de Salta Online</span> se reserva el
        derecho a buscar todas las soluciones disponibles por ley o equidad en
        relación con cualquier violación de estos Términos y condiciones,
        incluido el derecho a denegar o restringir el acceso a los Servicios,
        los Sitios Web, la plataforma de juego y la Información a una persona
        determinada, o a bloquear el acceso de una dirección de Internet
        específica o a través de otros dispositivos a los Servicios, los Sitios
        Web, plataforma de juego y la Información, en cualquier momento y a su
        exclusivo criterio, sin tener que proporcionar motivos cualesquiera.
      </p>

      <h6 className="text-center mb-3 mt-3">
        Derecho y jurisdicción aplicables
      </h6>

      <p className="text-small text-justify">
        La modalidad o sistema de apuestas objeto del presente reglamento, solo
        podrá comercializarse en el territorio de la provincia de Salta y/o en
        aquellas Jurisdicciones territoriales que mediante convenio suscripto
        pudieran adherir a la comercialización de la presente.
        <br></br>
        El usuario acepta que su acceso a los Servicios, a los Sitios Web, a la
        plataforma de juego, al Software y a la Información, la utilización de
        los mismos y la interpretación de estos Términos y condiciones se rijan
        e interpreten con arreglo a las leyes de la República Argentina
        sometiéndose a la competencia de los tribunales ordinarios de la
        Provincia de Salta, República Argentina, renunciando expresamente al
        fuero federal y/o cualquier otro fuero que pudiera corresponder.
      </p>
    </div>
  );
};

export default TerminosCondiciones;
