import React, { useEffect } from "react";
import NumberFormat from "react-number-format";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import { Animated } from "react-animated-css";
import styled from "styled-components";
import { estilosProv } from "../configProvincias";
import movimientosImg from "../img/icons/movimientos.svg";
import MovimientosIcon from "../img/movimientosIcon";

const Burgermenu = ({
  menu,
  refsetWrapperRef,
  estadoPlataforma,
  logueado,
  traerMensGrales,
  mensajesSinLeer,
  toggleMenu,
  version,
  volverAPlataforma,
  height,
  size,
}) => {
  const colorProv = estilosProv[process.env.REACT_APP_COD_ORGANIZACION].fondo;
  const colorFont =
    estilosProv[process.env.REACT_APP_COD_ORGANIZACION].fontColor;

  return (
    <>
      <Animated
        style={{ position: "relative", zIndex: "4" }}
        animationIn="fadeIn"
        animationInDuration={320}
        animationOut="fadeOut"
        animationOutDuration={350}
        //animateOnMount={false}
        isVisible={menu}
      >
        <div id="overlay-cont">
          <div id="overlay"></div>
        </div>
      </Animated>

      <div
        className={"collapse navbar-collapse show"}
        id="solapa"
        ref={refsetWrapperRef}
      >
        <div
          className="navbar-nav bg-light slide text-left init-burger"
          id="burgerMenu"
          style={{ width: size > 820 && height > 500 ? "25%" : "65%" }}
        >
          <div
            className={
              "bg-dark burger-header bg-prov-" +
              process.env.REACT_APP_COD_ORGANIZACION
            }
          >
            <div className={"text-center text-" + colorFont}>
              <h5 className="mt-2">
                <span className="m-bold">
                  {estadoPlataforma.nombre} {estadoPlataforma.apellido}
                </span>{" "}
              </h5>
            </div>
            <hr
              className={
                "w-75 mx-auto mb-1 mt-1 bg-prov-" +
                process.env.REACT_APP_COD_ORGANIZACION
              }
            ></hr>
            <div className={"text-center text-" + colorFont}>
              <div
                style={{
                  display: "block",
                  fontSize: "22px",
                }}
                className="sm-6"
              >
                <h6>saldo actual:</h6>
                <NumberFormat
                  style={{ fontSize: "22px" }}
                  className={"rounded m-heavy text-" + colorFont}
                  value={estadoPlataforma.saldo}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  prefix={"$ "}
                  decimalScale={2}
                  fixedDecimalScale={false}
                  isNumericString={true}
                />
              </div>
            </div>
          </div>

          <div className="slide-links ml-2">
            <div className="burgerLinks">
              <div //onClick={toggleMenu}
              >
                <Link to="/home">
                  <div
                    onClick={() => volverAPlataforma()}
                    className="pt-0 pb-0"
                  >
                    <span
                      className="iconify iconoLateral  mr-2 ml-2"
                      data-icon="bytesize:home"
                      data-inline="false"
                      data-width="20px"
                      color={colorProv}
                    ></span>
                    inicio
                  </div>
                </Link>
              </div>

              {logueado ? (
                <div //onClick={toggleMenu}
                >
                  <Link to="/consultaBilletera">
                    <div
                      onClick={() => volverAPlataforma()}
                      className="pt-0 pb-0 "
                    >
                      <span className="mr-2 ml-2">
                        <MovimientosIcon
                          color={colorProv}
                          width="20px"
                          height="20px"
                          efecto={false}
                        />
                      </span>
                      Movimientos
                    </div>
                  </Link>
                </div>
              ) : (
                <Redirect to="/login" />
              )}

              {process.env.REACT_APP_ENABLE_INGRESO_DINERO ? (
                <div>
                  <Link
                    to={
                      process.env.REACT_APP_ENABLE_INGRESO_DINERO
                        ? "/identificadorQRCliente/0"
                        : "/identificadorQRCliente"
                    }
                  >
                    <div
                      onClick={() => volverAPlataforma()}
                      className="pt-0 pb-0"
                    >
                      <span
                        className="iconify iconoLateral mr-2 ml-2"
                        data-icon="ion:wallet-outline"
                        data-width="20px"
                        color={colorProv}
                        data-inline="false"
                      ></span>
                      Billetera
                    </div>
                  </Link>
                </div>
              ) : (
                <div //onClick={toggleMenu}
                >
                  <Link
                    to={
                      process.env.REACT_APP_ENABLE_INGRESO_DINERO
                        ? "/identificadorQRCliente/1"
                        : "/identificadorQRCliente/"
                    }
                  >
                    <div
                      onClick={() => volverAPlataforma()}
                      className="pt-0 pb-0"
                    >
                      <span
                        className="iconify iconoLateral mr-2 ml-2"
                        data-icon="ion:wallet-outline"
                        data-width="20px"
                        color={colorProv}
                        data-inline="false"
                      ></span>
                      Retirar
                    </div>
                  </Link>
                </div>
              )}

              {logueado ? (
                <div //onClick={toggleMenu}
                  data-toggle="modal"
                  data-target={logueado ? "#notiModal" : ""}
                  onClick={traerMensGrales}
                >
                  <DivHover
                    className="pos-rel"
                    style={{ display: "inline", cursor: "pointer" }}
                  >
                    <span
                      style={{ verticalAlign: "sub" }}
                      className="iconify mr-2 ml-2"
                      data-icon="bytesize:bell"
                      data-inline="false"
                      data-width="20px"
                      color={colorProv}
                    ></span>

                    <span>Notificaciones</span>

                    {mensajesSinLeer !== 0 && (
                      <span
                        style={{ left: "110%" }}
                        className="badge badge-danger noti-badge"
                      >
                        {logueado ? mensajesSinLeer : ""}
                      </span>
                    )}
                  </DivHover>
                </div>
              ) : (
                <Redirect to="/login" />
              )}

              <div
                style={{ cursor: "pointer" }}
                id="accordion"
                ayuda="ayuda"
                className="ayuda"
                onClick={(e) => {
                  //toggleMenu();
                  let arrow = $(".help-arrow");
                  if (arrow.hasClass("rotate")) {
                    arrow.removeClass("rotate");
                  } else {
                    arrow.addClass("rotate");
                  }
                }}
              >
                <div
                  className="ayuda"
                  ayuda="ayuda"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span
                    ayuda="ayuda"
                    className="iconify mr-2 ml-2"
                    data-icon="bx:bx-help-circle"
                    data-inline="false"
                    data-width="20px"
                    color={colorProv}
                  ></span>
                  <span ayuda="ayuda" className="ayuda">
                    Ayuda
                  </span>

                  <span
                    ayuda="ayuda"
                    className="iconify help-arrow"
                    data-icon="dashicons:arrow-down-alt2"
                    data-inline="false"
                    data-width="25px"
                    color={colorProv}
                  ></span>
                </div>

                <div
                  ayuda="ayuda"
                  id="collapseOne"
                  className="collapse p-0"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <ul className="ayuda-cont">
                    <li
                      //onClick={toggleMenu}

                      data-toggle="modal"
                      data-target="#faqModal"
                      style={{ marginBottom: "2vh", marginTop: "1.2vh" }}
                    >
                      Preguntas Frecuentes
                    </li>
                    <li
                      //onClick={toggleMenu}

                      data-toggle="modal"
                      data-target="#responsableModal"
                      style={{ marginBottom: "2vh" }}
                    >
                      Juego Responsable
                    </li>
                    <li
                      //onClick={toggleMenu}

                      data-toggle="modal"
                      data-target="#contactoModal"
                      style={{ marginBottom: "2vh" }}
                    >
                      Contacto
                    </li>
                    <li
                      //onClick={toggleMenu}

                      data-toggle="modal"
                      data-target="#usoModal"
                      style={{ marginBottom: "2vh" }}
                    >
                      Uso Plataforma
                    </li>
                    <li
                      //onClick={toggleMenu}

                      data-toggle="modal"
                      data-target="#mediosModal"
                      style={{ marginBottom: "1.2vh" }}
                    >
                      Medios de Pago
                    </li>
                  </ul>
                </div>
              </div>

              {logueado ? (
                <div //onClick={toggleMenu}
                >
                  <Link to="/misDatosPersonales">
                    <div
                      onClick={() => volverAPlataforma()}
                      className="pt-0 pb-0"
                    >
                      <span
                        className="iconify  mr-2 ml-2"
                        data-width="20px"
                        color={colorProv}
                        data-icon="bi:person"
                        data-inline="false"
                      ></span>
                      Mi cuenta
                    </div>
                  </Link>
                </div>
              ) : (
                <Redirect to="/login" />
              )}

              <div
                style={{ cursor: "pointer" }}
                //onClick={toggleMenu}

                data-toggle="modal"
                data-target="#terminosModal"
              >
                <table>
                  <tbody>
                    <TrHover>
                      <td>
                        <span
                          className="iconify mr-2 ml-2"
                          data-icon="teenyicons:contract-outline"
                          data-inline="false"
                          data-width="18px"
                          color={colorProv}
                        ></span>
                      </td>
                      <td>Términos y condiciones</td>
                    </TrHover>
                  </tbody>
                </table>
              </div>

              {process.env.REACT_APP_ENABLE_AUTOEXCLUSION === "true" && (
                <Link to="/autoexclusion">
                  <div //onClick={toggleMenu}
                  >
                    <span
                      className="iconify mr-2 ml-2 mb-1"
                      data-icon="bx:bx-lock"
                      data-inline="false"
                      data-width="18px"
                      color={colorProv}
                    >
                      {" "}
                    </span>
                    {["13", "43", "20", "50"].includes(
                      process.env.REACT_APP_COD_ORGANIZACION
                    )
                      ? "Autolimitación"
                      : "Autoexclusión"}{" "}
                  </div>
                </Link>
              )}

              {logueado ? (
                <div //onClick={toggleMenu}
                >
                  {["11", "13", "23", "41", "43", "53"].includes(
                    process.env.REACT_APP_COD_ORGANIZACION
                  ) ? (
                    <></>
                  ) : (
                    <>
                      <span
                        className="iconify mr-2 ml-2 mb-1"
                        data-icon="ic:outline-star-rate"
                        data-inline="false"
                        data-width="18px"
                        color={colorProv}
                      ></span>
                      <a
                        style={{ cursor: "pointer" }}
                        data-toggle="modal"
                        data-target="#rateModal"
                      >
                        Calificar
                      </a>
                    </>
                  )}
                </div>
              ) : (
                <Redirect to="/" />
              )}
            </div>

            {logueado ? (
              <div
                style={{ cursor: "pointer" }}
                className="salir-cont pl-4 pt-1 pb-1"
              >
                <div
                  className="pt-0 pb-0"
                  data-toggle="modal"
                  data-target="#cerrarSesionModal"
                >
                  <span
                    className="iconify text-danger mr-2 ml-2 mb-1"
                    data-icon="bx:bx-log-out-circle"
                    data-inline="false"
                    data-flip="horizontal"
                    data-width="22px"
                    color={colorProv}
                  ></span>
                  Salir
                </div>
              </div>
            ) : (
              <Redirect to="/login" />
            )}

            <div
              className={
                "logo-slide bg-dark pt-1 pb-1 pl-2 pr-2 bg-prov-" +
                process.env.REACT_APP_COD_ORGANIZACION
              }
            >
              <span className={"text-x-small text-" + colorFont + " mr-3"}>
                Ver: {version}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Burgermenu;

const DivHover = styled.span`
  &:hover {
    color: #0056b3;
  }
`;
const TrHover = styled.span`
  &:hover {
    color: #0056b3;
  }
`;
