import React from "react";
import { useState } from "react";
import { estilosProv } from "../configProvincias";
const codOrg = process.env.REACT_APP_COD_ORGANIZACION;
console.log(codOrg);
const fontColor = estilosProv[codOrg].fontColor;
const fondo = estilosProv[codOrg].fondo;
const fondoSpan =
  estilosProv[process.env.REACT_APP_COD_ORGANIZACION].secundaryColor;

const EncabezaoRegistro = ({
  step1,
  step2,
  step3,
  logoHeader,
  volverHOME,
  size,
  setFocusStep1,
  setFocusStep2,
  setFocusStep3,
  setAnimacion,
}) => {
  const EncabezadoRegistroTabletyMobile = () => (
    /*
    <div
      className={
        "encabezadoRegistracion tablet bg-prov-" +
        process.env.REACT_APP_COD_ORGANIZACION
      }
      
    >
      */
    <div
      className={"encabezadoRegistracion tablet"}
      style={{ backgroundColor: fondo }}
      /* style={{backgroundColor:"#ffcc00"}} */
      /* style={
        ["20","50"].includes(
          process.env.REACT_APP_COD_ORGANIZACION
        )
        ? {backgroundColor: "#a32c32"}
        : {backgroundColor: "#ffcc00"}
      } */
    >
      <div
        className="back-register"
        style={
          ["13", "43", "11", "41", "23", "53", "20", "50", "48", "18"].includes(
            process.env.REACT_APP_COD_ORGANIZACION
          )
            ? { cursor: "pointer", color: "white" }
            : { cursor: "pointer", color: "black" }
        }
        onClick={volverHOME}
      >
        <span
          className="iconify"
          data-icon="fe:arrow-left"
          data-inline="false"
          data-width="25px"
        ></span>
      </div>

      <div
        className="text-center mt-3"
        style={
          ["20", "50"].includes(process.env.REACT_APP_COD_ORGANIZACION)
            ? { color: "white" }
            : { color: "black" }
        }
      >
        <h5
          className="m-0 m-bold"
          style={{ display: "inline", color: fontColor }}
        >
          ¡Hola!
        </h5>{" "}
        <span style={{ display: "inline", color: fontColor }}>
          ingresá tus datos!
        </span>
      </div>
      <div className="stepwizard" id="wizardBtn">
        <div className="stepwizard-row setup-panel">
          <div className="stepwizard-step">
            {step1 ? (
              <span
                type="button"
                className="btn btn-circle btn-success text-light pasos"
                style={{ backgroundColor: fondoSpan }}
              >
                1
              </span>
            ) : (
              <span
                type="button"
                className="btn text-light btn-dark btn-circle pasos"
                onClick={() => {
                  setAnimacion("animate__animated animate__backOutRight");
                  setTimeout(() => {
                    setFocusStep1();
                  }, 400);
                }}
              >
                1
              </span>
            )}
          </div>
          <div className="stepwizard-step">
            {step1 && (
              <span
                type="button"
                className="btn btn-circle btn-secondary text-light pasos"
                onClick={() => {
                  setAnimacion("animate__animated animate__backOutRight");
                  setTimeout(() => {
                    setFocusStep2();
                  }, 400);
                }}
              >
                2
              </span>
            )}

            {step2 && (
              <span
                type="button"
                className="btn btn-circle btn-success  text-light pasos"
                style={{ backgroundColor: fondoSpan }}
              >
                2
              </span>
            )}

            {step3 && (
              <span
                type="button"
                className="btn btn-circle btn-dark text-light pasos"
                onClick={() => {
                  setAnimacion("animate__animated  animate__backOutRight");
                  setTimeout(() => {
                    setFocusStep2();
                  }, 400);
                }}
              >
                2
              </span>
            )}
          </div>
          <div className="stepwizard-step">
            {step3 ? (
              <span
                type="button"
                className="btn btn-circle btn-success  text-light pasos"
                style={{ backgroundColor: fondoSpan }}
              >
                3
              </span>
            ) : (
              <span
                type="button"
                className="btn text-light btn-secondary btn-circle pasos"
                onClick={(e) => {
                  setAnimacion("animate__animated animate__backOutLeft");
                  setTimeout(() => {
                    setFocusStep3();
                  }, 400);
                }}
              >
                3
              </span>
            )}
          </div>
        </div>
      </div>

      {step1 && (
        <div className="datoSPersonales" style={{ color: fontColor }}>
          <h5 style={{ display: "inline" }}> Paso 1 </h5>
          <span>- Datos personales</span>
        </div>
      )}

      {step2 && (
        <div className="datoSPersonales" style={{ color: fontColor }}>
          <h5 style={{ display: "inline" }}> Paso 2 </h5>
          <span>- Datos de contacto</span>
        </div>
      )}

      {step3 && (
        <div className="datoSPersonales" style={{ color: fontColor }}>
          <h5 style={{ display: "inline" }}> Paso 3 </h5>
          <span>- Datos de seguridad</span>
        </div>
      )}
    </div>
  );

  const EncabezadoRegistroDesktop = () => (
    <div
      className={
        "encabezadoRegistracionWeb bg-prov-" +
        process.env.REACT_APP_COD_ORGANIZACION
      }
      /*className="encabezadoRegistracionWeb"*/
      /* style={{backgroundColor: "#ffccaa"}} */
      style={
        { backgroundColor: fondo }
        /* ["20","50"].includes(
          process.env.REACT_APP_COD_ORGANIZACION
          )
          ? {backgroundColor: "#a32c32"}
          : {backgroundColor: "#ffccaa"} */
      }
    >
      <div
        className="back-register"
        style={
          ["13", "43", "11", "41", "23", "53", "20", "50", "48", "18"].includes(
            process.env.REACT_APP_COD_ORGANIZACION
          )
            ? { cursor: "pointer", color: "white" }
            : { cursor: "pointer", color: "black" }
        }
        onClick={volverHOME}
      >
        <span
          className="iconify"
          data-icon="fe:arrow-left"
          data-inline="false"
          data-width="25px"
        ></span>
      </div>

      <img src={logoHeader} className="logoHeaderReg" />

      <div className="text-center mt-3">
        <h5
          className="m-0 m-bold black"
          style={{
            display: "inline",
            position: "absolute",
            top: "140%",
            left: "34%",
          }}
        >
          Ingresá tus datos para poder jugar al instante.
        </h5>
      </div>

      <div className="stepwizard" id="wizardBtn">
        <div className="stepwizard-row setup-panel">
          <div className="stepwizard-step">
            {step1 ? (
              <span
                type="button"
                className="btn btn-circle btn-success text-light pasos"
                style={{ backgroundColor: fondoSpan }}
              >
                1
              </span>
            ) : (
              <span
                type="button"
                className="btn text-light btn-dark btn-circle pasos"
                onClick={() => {
                  setAnimacion("animate__animated animate__backOutRight");
                  setTimeout(() => {
                    setFocusStep1();
                  }, 600);
                }}
              >
                1
              </span>
            )}
          </div>
          <div className="stepwizard-step">
            {step1 && (
              <span
                type="button"
                className="btn btn-circle btn-secondary text-light pasos"
                onClick={() => {
                  setAnimacion("animate__animated animate__backOutLeft");
                  setTimeout(() => {
                    setFocusStep2();
                  }, 600);
                }}
              >
                2
              </span>
            )}

            {step2 && (
              <span
                type="button"
                className="btn btn-circle btn-success  text-light pasos"
                style={{ backgroundColor: fondoSpan }}
              >
                2
              </span>
            )}

            {step3 && (
              <span
                type="button"
                className="btn btn-circle btn-dark text-light pasos"
                onClick={() => {
                  setAnimacion("animate__animated  animate__backOutRight");
                  setTimeout(() => {
                    setFocusStep2();
                  }, 600);
                }}
              >
                2
              </span>
            )}
          </div>
          <div className="stepwizard-step">
            {step3 ? (
              <span
                type="button"
                className="btn btn-circle btn-success  text-light pasos"
                style={{ backgroundColor: fondoSpan }}
              >
                3
              </span>
            ) : (
              <span
                type="button"
                className="btn text-light btn-secondary btn-circle pasos"
                onClick={(e) => {
                  setAnimacion("animate__animated animate__backOutLeft");
                  setTimeout(() => {
                    setFocusStep3();
                  }, 600);
                }}
              >
                3
              </span>
            )}
          </div>
        </div>
      </div>

      <h4
        className="m-bold"
        style={
          ["13", "43", "11", "41", "23", "53", "20", "50", "48", "18"].includes(
            process.env.REACT_APP_COD_ORGANIZACION
          )
            ? {
                position: "absolute",
                top: "1.5rem",
                right: "1rem",
                color: "white",
              }
            : {
                position: "absolute",
                top: "1.5rem",
                right: "1rem",
                color: "black",
              }
        }
      >
        REGISTRO
      </h4>

      {step1 && (
        <div className="datoSPersonales black">
          <h5 style={{ display: "inline" }}> Paso 1 </h5>
          <span>- Datos personales</span>
        </div>
      )}

      {step2 && (
        <div className="datoSPersonales black">
          <h5 style={{ display: "inline" }}> Paso 2 </h5>
          <span>- Datos de contacto</span>
        </div>
      )}

      {step3 && (
        <div className="datoSPersonales black">
          <h5 style={{ display: "inline" }}> Paso 3 </h5>
          <span>- Datos de seguridad</span>
        </div>
      )}
    </div>
  );

  return size > 600 ? (
    <EncabezadoRegistroDesktop />
  ) : (
    <EncabezadoRegistroTabletyMobile />
  );
};

export default EncabezaoRegistro;
