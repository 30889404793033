/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import ReactDOM from "react-dom";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { instalaciones } from "./instalaciones";

import OlvideClave from "./olvideClave";

import Registrarse from "./Registrarse.jsx";
import ActualizarVersion from "./actualizarVersion";
import Login from "./login";
import ConsultaBilletera from "./screens/consultaBilletera";
import IdentificadorQRCliente from "./screens/identificadorQRCliente";
import MisDatosPersonales from "./screens/misDatosPersonales";
import Home from "./screens/home";
import NavBar from "./navBarCF";

import Autoexclusion from "./autoexclusion";
import SplashScreen from "./splashScreen";

import $ from "jquery";
import "jquery-confirm/css/jquery-confirm.css";
import "jquery-confirm";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "./scss/style.css";

import packageJson from "../package.json";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import ModalJuegoResponsable from "./modalJuegoResponsable";
import Juego from "./screens/juego";
import { ThemeConsumer } from "styled-components";
import RetiroDinero from "./screens/retiroDinero.jsx";
/* import * as from './sw.js'; */

global.appVersion = packageJson.version;
global.appHomepage = process.env.REACT_APP_HOMEPAGE;
/* 
const Contactos = require(process.env.REACT_APP_PAGE_CONTACTO).default;
const PreguntasFrecuentes = require(process.env.REACT_APP_PAGE_PREG_FREC).default;
const JuegoResponsable = require(process.env.REACT_APP_PAGE_JUE_RESP).default;
const TerminosCondiciones = require(process.env.REACT_APP_PAGE_TERM_COND).default;
const MediosPago = require(process.env.REACT_APP_PAGE_MEDIOS_PAGO).default;
 */

/*
Clase: ContenedorPlataforma
Responsabilidad: Muestra la pagina principal de la plataforma.
Creada por: S. Nimo Fecha : 18/04/2020
*/

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
};
// eslint-disable-next-line no-unused-vars
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  return isTablet ? children : null;
};
// eslint-disable-next-line no-unused-vars
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
// eslint-disable-next-line no-unused-vars
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};

/* const Tablet = ({ children }) => {
const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
return isTablet ? children : null
} */
class ContenedorPlataforma extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logueado: false,
      autoexcluidoHasta: null,
      acceptedTyc: null,
      nombre: "",
      apellido: "",
      userToken: "",
      saldo: "",
      estado: "PLATAFORMA",
      htmlJuego: "",
      notifJuegoResponsable: false,
      operRequirioLogin: "",
      cargandoJuego: false,
      tyc: null,
      fetching: true,
      currentPage: "",
      redirect: "/",
      juegoActual: "",
      manualJuego: undefined,
      notificacionesJuego: undefined,
      provincia: instalaciones[process.env.REACT_APP_COD_ORGANIZACION],
      requiereCambio: false,
      cuil: undefined,
    };

    this.login = this.login.bind(this);
    this.setSaldo = this.setSaldo.bind(this);
    this.setCambio = this.setCambio.bind(this);
    this.cerrarSesion = this.cerrarSesion.bind(this);
    this.volverAPlataforma = this.volverAPlataforma.bind(this);
    this.getUserToken = this.getUserToken.bind(this);
    this.arrancarJuego = this.arrancarJuego.bind(this);
    this.clickVolerAPlataforma = this.clickVolerAPlataforma.bind(this);
    this.refreshSaldo = this.refreshSaldo.bind(this);
    this.getMensajes = this.getMensajes.bind(this);
    this.mensajeErrorWS = this.mensajeErrorWS.bind(this);
    this.mensajeErrorGeneral = this.mensajeErrorGeneral.bind(this);
    this.requirioLoginElLogin = this.requirioLoginElLogin.bind(this);
    this.requirioLoginJuego = this.requirioLoginJuego.bind(this);
    this.terminoCargaJuego = this.terminoCargaJuego.bind(this);
    this.actualizarVersion = this.actualizarVersion.bind(this);
    this.isLogueado = this.isLogueado.bind(this);
    this.isCambiarVersion = this.isCambiarVersion.bind(this);
    this.actuVersionSiCorresponde = this.actuVersionSiCorresponde.bind(this);
    this.getCodigoOrganizacion = this.getCodigoOrganizacion.bind(this);
    this.getCodigoOrganizacionFetch =
      this.getCodigoOrganizacionFetch.bind(this);
    this.getUrlOrganizacion = this.getUrlOrganizacion.bind(this);
    this.getUserName = this.getUserName.bind(this);
    this.getUserLastName = this.getUserLastName.bind(this);
    this.initTerminosCondiciones = this.initTerminosCondiciones.bind(this);
    this.agreeTerm = this.agreeTerm.bind(this);
    this.logCurrentPage = this.logCurrentPage.bind(this);
    this.getSaldo = this.getSaldo.bind(this);
    this.getCuil = this.getCuil.bind(this);
    this.generarEvento = this.generarEvento.bind(this);
    this.setCuil = this.setCuil.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.cargarImgDinamica = this.cargarImgDinamica.bind(this);
    this.traerDatosJuegoResponsable =
      this.traerDatosJuegoResponsable.bind(this);
    this.getNotifJuegoResponsable = this.getNotifJuegoResponsable.bind(this);
    this.setJuegoActual = this.setJuegoActual.bind(this);
    this.setmanualJuego = this.setmanualJuego.bind(this);
    this.getJuegoActual = this.getJuegoActual.bind(this);
    this.setnotificacionesJuego = this.setnotificacionesJuego.bind(this);
    this.notificacionMensajes = {
      ejecutar: null,
    };

    window.gotoPlataforma = this.volverAPlataforma;
    window.getUserToken = this.getUserToken;
    window.cerrarSesion = this.cerrarSesion;
    window.cerrarSesionConMes = this.cerrarSesionConMes;
    window.isLogueado = this.isLogueado;
    window.getCodigoOrganizacion = this.getCodigoOrganizacion;
    window.getCodigoOrganizacionFetch = this.getCodigoOrganizacionFetch;
    window.getUrlOrganizacion = this.getUrlOrganizacion;
    window.getUserName = this.getUserName;
    window.getUserLastName = this.getUserLastName;
    window.getSaldo = this.getSaldo;
    window.handleRedirect = this.handleRedirect;
    window.getNotifJuegoResponsable = this.getNotifJuegoResponsable;
    window.setmanualJuego = this.setmanualJuego;
    window.setnotificacionesJuego = this.setnotificacionesJuego;
    window.getJuegoActual = this.getJuegoActual;
    window.refreshSaldo = this.refreshSaldo;
    window.getMensajes = this.getMensajes;
    window.setCambio = this.setCambio;
    window.setCuil = this.setCuil;
    window.getCuil = this.getCuil;
    window.generarEvento = this.generarEvento;
  }

  cargarImgDinamica(nombre1, nombre2) {
    import(
      `./img/${nombre1}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      this.setState({ logoVertical: image.default });
    });
    import(
      `./img/${nombre2}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      this.setState({ logoHorizontal: image.default });
    });
  }
  getJuegoActual() {
    return this.state.juegoActual;
  }

  handleRedirect(pagina) {
    this.setState({ redirect: pagina });
  }

  logCurrentPage(page) {
    this.setState({ currentPage: page });
  }

  componentDidMount() {
    this.cargarImgDinamica("VERTICAL_ORG", "HORIZONTAL_ORG");

    window.scrollTo(0, 0);

    window.addEventListener("beforeinstallprompt", (e) => {
      // For older browsers
      e.preventDefault();

      this.installPrompt = e;
      // See if the app is already installed, in that case, do nothing
      if (
        (window.matchMedia &&
          window.matchMedia("(display-mode: standalone)").matches) ||
        window.navigator.standalone === true
      ) {
        return false;
      }
      // Set the state variable to make button visible
      this.setState({
        installButton: true,
      });
    });

    this.SplashScreenFn();

    var sBrowser,
      sUsrAg = navigator.userAgent;

    if (sUsrAg.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox";
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      sBrowser = "Samsung Internet";
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      sBrowser = "Opera";
    } else if (sUsrAg.indexOf("Trident") > -1) {
      sBrowser = "Microsoft Internet Explorer";
    } else if (sUsrAg.indexOf("Edge") > -1) {
      sBrowser = "Microsoft Edge";
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      sBrowser = "Chrome";
    } else if (sUsrAg.indexOf("Safari") > -1) {
      sBrowser = "Apple Safari";
    } else {
      sBrowser = "unknown";
    }

    var _originalSize = $(window).width() + $(window).height();

    if (sBrowser !== "Samsung Internet") {
      $(window).resize(function () {
        if ($(window).width() + $(window).height() !== _originalSize) {
          //$("#shortcutLog").addClass("hide");
        } else {
          //$("#shortcutLog").removeClass("hide");
        }
      });
    }
  }

  componentDidUpdate() {
    if (this.state.logueado && this.state.tyc === null) {
      this.initTerminosCondiciones();
    }
  }

  installApp = async () => {
    if (!this.installPrompt) return false;
    this.installPrompt.prompt();
    let outcome = await this.installPrompt.userChoice;
    if (outcome.outcome == "accepted") {
    } else {
    }
    // Remove the event reference
    this.installPrompt = null;
    // Hide the button
    this.setState({
      installButton: false,
    });
  };

  agreeTerm() {
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_GUARDAR_TERMINOS_CONDICIONES;
    url = url.replace(":idTyc", this.state.tyc.id);

    let cerrarSesion = false;
    let statusCode = "";

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + this.state.userToken,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          this.setState({ acceptedTyc: true });
          $("#tycModal").modal("hide");
        } else if (json.status === "error") {
          if (cerrarSesion) {
            this.mensajeErrorWS(
              "Términos y Condiciones",
              json.errores,
              cerrarSesion
            );
          } else {
            this.setState({
              errores: json.errores,
              cambioExitoso: false,
            });
            this.mensajeErrorWS("Términos y Condiciones", json.errores);
          }
        }
      })
      .catch((error) => {
        this.mensajeErrorGeneral();
      });
  }

  initTerminosCondiciones() {
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_TERMINOS_CONDICIONES_REG +
      process.env.REACT_APP_COD_ORGANIZACION;

    let statusCode = "";

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + this.state.userToken,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;

        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          this.cerrarSesion();
          this.history.push("/");
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          this.setState({ tyc: json });
          this.setState({ fetching: false });
        } else if (json.status === "error") {
          this.mensajeErrorWS("Terminos y condiciones", json.errores);
        }
      })
      .catch((error) => {
        this.mensajeErrorGeneral();
      });
  }

  isLogueado() {
    return this.state.logueado;
  }

  terminoCargaJuego() {
    this.setState({ cargandoJuego: false });
  }

  requirioLoginElLogin() {
    this.setState({ operRequirioLogin: "LOGIN" });
  }

  requirioLoginJuego(urlJuego) {
    this.setState({ operRequirioLogin: urlJuego });
  }

  async actualizarVersion() {
    //AGREGADO 16/10/2020
    if (caches) {
      const names = await caches.keys();
      await Promise.all(names.map((name) => caches.delete(name)));
    }

    //window.location.reload(true);
    window.location.reload();
  }

  mensajeErrorGeneral() {
    //let pathImagen = process.env.REACT_APP_HOMEPAGE+'/sorprendido.png';

    $.alert({
      title: "<h4>Ups. </h4>",
      content:
        '<div className="text-center pb-2 mt-2">Favor reintente más tarde. Verifique la conexión a internet.</div>',
      buttons: {
        continuar: function () {},
      },
    });
  }

  mensajeErrorWS(titulo, errores, cerrarSesion) {
    //let pathImagen = process.env.REACT_APP_HOMEPAGE+'/sorprendido.png';

    let imagen =
      "<div className='text-center pt-2 mb-2'><span className='material-icons mr-2 text-warning text-center' style='font-size: 50px'>error_outline</span></div>";

    // Verificar si se informa el codigo de cierre de sesion
    let i = 0;
    let mensaje = "";

    for (i = 0; i < errores.length; ++i) {
      mensaje = mensaje + "<div>" + errores[i].error + "</div>";
    }

    let objThis = this;

    $.alert({
      title: titulo,
      content: '<div className="text-center pb-2 mt-2">' + mensaje + "</div>",
      buttons: {
        continuar: function () {
          if (cerrarSesion) {
            sessionStorage.removeItem("Usuario");
            sessionStorage.removeItem("login");
            objThis.cerrarSesion();
            objThis.volverAPlataforma();
          }
        },
      },
    });
  }

  getNotifJuegoResponsable() {
    return this.state.notifJuegoResponsable;
  }

  actuVersionSiCorresponde(props) {
    let statusCode = "";

    fetch(global.appHomepage + "/meta.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        return respPromise.json();
      })
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = this.isCambiarVersion(
          latestVersion,
          currentVersion
        );

        if (shouldForceRefresh) {
          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
              for (let name of names) caches.delete(name);
            });
          }
          if (typeof props != "undefined") {
            if (typeof props.history != "undefined")
              props.history.push("/actualizarVersion");
            window.location.reload(true);
          }
        } else {
          this.setState({ loading: false, isLatestVersion: true });
        }
      })
      .catch((error) => {});
  }

  isCambiarVersion(versionA, versionB) {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = String(versionsA.shift());

      const b = String(versionsB.shift());
      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a !== b || isNaN(b);
    }
    return false;
  }

  mostrarJuegosResponsable(clickInternoJugar) {
    this.traerDatosJuegoResponsable(clickInternoJugar);
  }

  traerDatosJuegoResponsable(funcionJugar) {
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_DATOS_JUEGO_RESPONSABLE;

    let statusCode = "";
    let objThis = this;
    let miFuncionJugar = funcionJugar;
    let token = this.getUserToken();

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + this.state.userToken,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          this.props.cerrarSesion();
          this.props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          this.setState({
            juegoResponsable: {
              titulo: json.titulo,
              horasSesion: json.horasSesion,
              minutosSesion: json.minutosSesion,
              jugadoJornada: json.jugadoJornada,
              jugadoSesion: json.jugadoSesion,
              balanceJornada: json.balanceJornada,
              balanceSesion: json.balanceSesion,
              premiosJornada: json.premiosJornada,
              premiosSesion: json.premiosSesion,
            },
          });

          $.confirm({
            title: '<b className="op-text">Datos juego responsable</b>',
            backgroundDismiss: true,
            columnClass: "medium",
            animation: "zoom",
            closeIcon: true,
            closeAnimation: "scale",
            content: `
								<div className="modal-body" style={{maxHeight:"300px", overflow:"scroll"}}>
									<div className="modal-body text-center mb-2 pb-5" style={{overflowY:"scroll", height:"90vh"}}>
										<h5><u>${this.state.juegoResponsable.titulo}</u></h5>
										<p><u>Balance Jornada: </u>${this.state.juegoResponsable.balanceJornada}</p>
										<p><u>Jugado Jornada: </u>${this.state.juegoResponsable.jugadoJornada}</p>
										<p><u>Premios Jornada: </u>${this.state.juegoResponsable.premiosJornada}</p>
										<h5><u>Datos de la sesion</u></h5>
										<p>Usted lleva ${this.state.juegoResponsable.horasSesion}hrs ${this.state.juegoResponsable.minutosSesion}min jugando</p>
										<p><u>Premios Sesion: </u>${this.state.juegoResponsable.premiosSesion}</p>
										<p><u>Jugado Sesion: </u>${this.state.juegoResponsable.jugadoSesion}</p>
										<p><u>Balance Sesion: </u>${this.state.juegoResponsable.balanceSesion}</p>
									</div>
                            </div>`,
            buttons: {
              No: {
                text: "Cerrar Sesion",
                action: function () {
                  objThis.cerrarSesion();
                },
              },
              Si: {
                text: "Continuar",
                action: function () {
                  miFuncionJugar();
                  return;
                },
              },
            },
          });
        } else if (json.status === "error") {
          this.props.mensajeErrorWS(
            "Datos iniciales mensajería",
            statusCode,
            json.errores,
            false
          );
          this.props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
      })
      .catch((error) => {
        this.props.mensajeErrorWS(
          "Datos iniciales mensajería",
          statusCode,
          [{ codigo: "0", error: error.message }],
          true
        );
      });
  }

  login(
    aNombre,
    aApellido,
    aUserToken,
    usrExlcuidoHasta,
    aceptoTyc,
    nps,
    requiereCambio
  ) {
    this.setState({
      logueado: true,
      nombre: aNombre,
      apellido: aApellido,
      userToken: aUserToken,
      autoexcluidoHasta: usrExlcuidoHasta,
      acceptedTyc: aceptoTyc,
      nps: nps,
      tyc: null,
      requiereCambio: requiereCambio,
    });
    this.initTerminosCondiciones();
  }

  clickVolerAPlataforma() {
    this.volverAPlataforma();
  }

  setSaldo(aSaldo) {
    this.setState({
      saldo: aSaldo,
    });
  }
  setCambio(aCambio) {
    this.setState({
      requiereCambio: aCambio,
    });
  }

  setCuil(cuil) {
    this.setState({
      cuil: cuil,
    });
  }

  volverAPlataforma() {
    this.refreshSaldo();
    this.setState({
      estado: "PLATAFORMA",
      manualJuego: undefined,
      notificacionesJuego: undefined,
    });
  }

  getUserName() {
    return this.state.nombre;
  }

  getUserLastName() {
    return this.state.apellido;
  }

  getUserToken() {
    return this.state.userToken;
  }

  getSaldo() {
    return this.state.saldo;
  }

  getCuil() {
    return this.state.cuil;
  }

  generarEvento(eventoTittle, item, value) {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push({
      event: eventoTittle,
      itemName: item,
      value: value,
    });
  }

  getCodigoOrganizacion() {
    return process.env.REACT_APP_COD_ORGANIZACION;
  }

  getCodigoOrganizacionFetch() {
    return process.env.REACT_APP_COD_ORGANIZACION_FETCH;
  }

  getUrlOrganizacion() {
    return process.env.REACT_APP_URL_ORGANIZACION;
  }

  arrancarJuego(aHTMLJuego) {
    this.setState({
      estado: "JUEGO",
      htmlJuego: aHTMLJuego,
      cargandoJuego: true,
    });
  }

  cerrarSesion() {
    this.setState({
      estado: "PLATAFORMA",
      logueado: false,
      autoexcluidoHasta: null,
      acceptedTyc: null,
      nombre: "",
      userToken: "",
      saldo: 0,
    });

    sessionStorage.removeItem("Usuario");
    sessionStorage.removeItem("login");
    //window.location.href="/home"
  }

  cerrarSesionConMes() {
    this.cerrarSesion();
  }

  refreshSaldo() {
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_GET_SALDO;

    let statusCode = "";

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + this.state.userToken,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          this.cerrarSesion();
          window.location.reload(true);
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          this.setState({ saldo: json.saldo });
        } else if (json.status === "error") {
          // Cerrrar Sesion
          //this.cerrarSesion();
        }
        // No mostrar mensaje de error a proposito
        //this.mensajeErrorGeneral();
      })
      .catch((error) => {
        // No mostrar mensaje de error a proposito
        this.mensajeErrorWS();
      });
  }

  getMensajes() {}

  SplashScreenFn() {
    let splash = $(".splash");
    setTimeout(function () {
      splash.fadeOut();
    }, 1800);
  }

  setJuegoActual(juego) {
    this.setState({ juegoActual: juego });
  }
  setmanualJuego(fn) {
    this.setState({ manualJuego: fn });
  }
  setnotificacionesJuego(nt) {
    this.setState({ notificacionesJuego: nt });
  }

  render() {
    return (
      <div>
        {this.state.userToken ? (
          <ModalJuegoResponsable
            userToken={this.state.userToken}
            cerrarSesion={this.cerrarSesion}
          />
        ) : (
          <></>
        )}
        <div id="splashOut">
          <SplashScreen className="splash" id="splash" />
        </div>

        <HeaderPlataforma
          redirect={this.state.redirect}
          logCurrentPage={this.logCurrentPage}
          currentPage={this.state.currentPage}
          notificacionMensajes={this.notificacionMensajes}
          actuVersionSiCorresponde={this.actuVersionSiCorresponde}
          actualizarVersion={this.actualizarVersion}
          requirioLoginElLogin={this.requirioLoginElLogin}
          requirioLoginJuego={this.requirioLoginJuego}
          saldo={this.state.saldo}
          requiereCambio={this.state.requiereCambio}
          nombre={this.state.nombre}
          logueado={this.state.logueado}
          login={this.login}
          arrancarJuego={this.arrancarJuego}
          setSaldo={this.setSaldo}
          setCambio={this.setCambio}
          estadoPlataforma={this.state}
          agreeTerm={this.agreeTerm}
          cerrarSesion={this.cerrarSesion}
          mensajeErrorGeneral={this.mensajeErrorGeneral}
          mensajeErrorWS={this.mensajeErrorWS}
          refreshSaldo={this.refreshSaldo}
          volverAPlataforma={this.volverAPlataforma}
          installApp={this.installApp}
          instalable={this.state.installButton}
          juegoActual={this.state.juegoActual}
          setJuegoActual={this.setJuegoActual}
          manualJuego={this.state.manualJuego}
          notificacionesJuego={this.state.notificacionesJuego}
          terminoCargaJuego={this.terminoCargaJuego}
        />
      </div>
    );
  }
}

class ProveedorJuego extends React.Component {
  constructor(props) {
    super(props);

    this.cargoElIFrame = this.cargoElIFrame.bind(this);
    this.terminoCarga = this.terminoCarga.bind(this);
  }

  cargoElIFrame() {
    this.props.terminoCargaJuego();
  }

  terminoCarga() {
    $("#zonaJuego").attr(
      "src",
      this.props.estadoPlataforma.htmlJuego + "?a=" + Math.random()
    );
  }

  componentDidMount() {
    this.terminoCarga();
  }

  render() {
    return this.props.estadoPlataforma.estado === "PLATAFORMA" ? (
      <></>
    ) : (
      <iframe
        onLoad={this.cargoElIFrame}
        style={{ visibility: "visible" }}
        title="juego"
        id="zonaJuego"
        frameBorder="0"
      ></iframe>
    );
  }
}

class HeaderPlataforma extends React.Component {
  parametros = {};

  constructor(props) {
    super(props);
    this.state = {
      logueado: false,
      currentPage: "PRUEBA",
      redirect: true,
    };

    this.refreshConsultaBilletera = { laFuncion: null };
    this.handleRemember = this.handleRemember.bind(this);
  }

  fxFixed() {
    var $footer = $("#zonaJuego").contents().find(".shortcutLog");
    $footer.css({
      position: "fixed",
      bottom: "0",
      right: "0",
      "z-index": "10",
    });
  }

  handleRemember(tipo) {
    if (tipo === "toggle") {
      if ($("#remember_me").is(":checked")) {
        // save username and password
        // localStorage.usrname = $('#nroDocu').val();
        // localStorage.pass = $('#clave').val();
        localStorage.chkbx = $("#remember_me").val();
      } else {
        localStorage.usrname = "";
        localStorage.pass = "";
        localStorage.chkbx = "";
      }
    }

    if (tipo === "erase") {
      localStorage.usrname = "";
      localStorage.pass = "";
      localStorage.chkbx = "";
    }
  }

  render() {
    return (
      <div>
        <Router basename={process.env.REACT_APP_HOMEPAGE}>
          {this.props.estadoPlataforma.logueado && (
            <NavBar
              agreeTerm={this.props.agreeTerm}
              arrancarJuego={this.props.arrancarJuego}
              actuVersionSiCorresponde={this.props.actuVersionSiCorresponde}
              currentPage={this.props.currentPage}
              refreshConsultaBilletera={this.refreshConsultaBilletera}
              estadoPlataforma={this.props.estadoPlataforma}
              notificacionMensajes={this.props.notificacionMensajes}
              requirioLoginElLogin={this.props.requirioLoginElLogin}
              requirioLoginJuego={this.props.requirioLoginJuego}
              logueado={this.props.estadoPlataforma.logueado}
              traerDatosJuegoResponsable={this.props.traerDatosJuegoResponsable}
              nombre={this.props.nombre}
              saldo={this.props.saldo}
              refreshSaldo={this.props.refreshSaldo}
              volverAPlataforma={this.props.volverAPlataforma}
              version={global.appVersion}
              installApp={this.props.installApp}
              instalable={this.props.instalable}
              cerrarSesion={this.props.cerrarSesion}
              redirect={this.props.redirect}
              mensajeErrorGeneral={this.props.mensajeErrorGeneral}
              mensajeErrorWS={this.props.mensajeErrorWS}
              juegoActual={this.props.juegoActual}
              manualJuego={this.props.manualJuego}
              notificacionesJuego={this.props.notificacionesJuego}
            />
          )}

          <div
            className="cuerpoIndex"
            id="cont_escalable"
            style={
              !navigator.vendor.toLocaleLowerCase().includes("apple")
                ? { overflow: "scroll", height: "77vh", marginTop: "4vh" }
                : {}
            }
          >
            <Switch>
              <Route
                path="/autoexclusion"
                render={(props) => (
                  <Autoexclusion
                    notificacionMensajes={this.props.notificacionMensajes}
                    estadoPlataforma={this.props.estadoPlataforma}
                    actuVersionSiCorresponde={
                      this.props.actuVersionSiCorresponde
                    }
                    mensajeErrorGeneral={this.props.mensajeErrorGeneral}
                    mensajeErrorWS={this.props.mensajeErrorWS}
                    cerrarSesion={this.props.cerrarSesion}
                  />
                )}
              />

              <Route
                path="/actualizarVersion"
                render={(props) => (
                  <ActualizarVersion
                    notificacionMensajes={this.props.notificacionMensajes}
                    actuVersionSiCorresponde={
                      this.props.actuVersionSiCorresponde
                    }
                    actualizarVersion={this.props.actualizarVersion}
                    mensajeErrorGeneral={this.props.mensajeErrorGeneral}
                    mensajeErrorWS={this.props.mensajeErrorWS}
                    cerrarSesion={this.props.cerrarSesion}
                  />
                )}
              />

              <Route
                path="/olvideClave"
                render={(props) => (
                  <OlvideClave
                    notificacionMensajes={this.props.notificacionMensajes}
                    actuVersionSiCorresponde={
                      this.props.actuVersionSiCorresponde
                    }
                    mensajeErrorGeneral={this.props.mensajeErrorGeneral}
                    mensajeErrorWS={this.props.mensajeErrorWS}
                    cerrarSesion={this.props.cerrarSesion}
                  />
                )}
              />

              <Route
                path="/misDatosPersonales"
                render={(props) =>
                  this.props.estadoPlataforma.logueado ? (
                    <MisDatosPersonales
                      notificacionMensajes={this.props.notificacionMensajes}
                      actuVersionSiCorresponde={
                        this.props.actuVersionSiCorresponde
                      }
                      estadoPlataforma={this.props.estadoPlataforma}
                      mensajeErrorGeneral={this.props.mensajeErrorGeneral}
                      mensajeErrorWS={this.props.mensajeErrorWS}
                      handleRemember={this.handleRemember}
                      cerrarSesion={this.props.cerrarSesion}
                      logCurrentPage={this.props.logCurrentPage}
                      currentPage={this.state.curretPage}
                    />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />

              <Route
                path="/registrarse"
                render={(props) => (
                  <Registrarse
                    notificacionMensajes={this.props.notificacionMensajes}
                    actuVersionSiCorresponde={
                      this.props.actuVersionSiCorresponde
                    }
                    estadoPlataforma={this.props.estadoPlataforma}
                    mensajeErrorGeneral={this.props.mensajeErrorGeneral}
                    mensajeErrorWS={this.props.mensajeErrorWS}
                    cerrarSesion={this.props.cerrarSesion}
                    logCurrentPage={this.props.logCurrentPage}
                    currentPage={this.state.curretPage}
                  />
                )}
              />

              <Route
                path="/consultaBilletera"
                render={(props) =>
                  this.props.estadoPlataforma.logueado ? (
                    <ConsultaBilletera
                      refreshConsultaBilletera={this.refreshConsultaBilletera}
                      notificacionMensajes={this.props.notificacionMensajes}
                      actuVersionSiCorresponde={
                        this.props.actuVersionSiCorresponde
                      }
                      estadoPlataforma={this.props.estadoPlataforma}
                      mensajeErrorGeneral={this.props.mensajeErrorGeneral}
                      mensajeErrorWS={this.props.mensajeErrorWS}
                      cerrarSesion={this.props.cerrarSesion}
                      logCurrentPage={this.props.logCurrentPage}
                      currentPage={this.state.curretPage}
                      refreshSaldo={this.props.refreshSaldo}
                      volverAPlataforma={this.props.volverAPlataforma}
                    />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />

              <Route
                path={
                  process.env.REACT_APP_ENABLE_INGRESO_DINERO
                    ? "/identificadorQRCliente/:ingresoretiro"
                    : "/identificadorQRCliente/"
                }
                render={(props) =>
                  this.props.estadoPlataforma.logueado ? (
                    <IdentificadorQRCliente
                      notificacionMensajes={this.props.notificacionMensajes}
                      actuVersionSiCorresponde={
                        this.props.actuVersionSiCorresponde
                      }
                      estadoPlataforma={this.props.estadoPlataforma}
                      mensajeErrorGeneral={this.props.mensajeErrorGeneral}
                      mensajeErrorWS={this.props.mensajeErrorWS}
                      cerrarSesion={this.props.cerrarSesion}
                      logCurrentPage={this.props.logCurrentPage}
                      currentPage={this.state.curretPage}
                      refreshSaldo={this.props.refreshSaldo}
                    />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />

              <Route
                path="/juego/:nombrejuego"
                render={(props) =>
                  this.props.estadoPlataforma.logueado ? (
                    <Juego
                      notificacionMensajes={this.props.notificacionMensajes}
                      terminoCargaJuego={this.props.terminoCargaJuego}
                      requirioLoginJuego={this.props.requirioLoginJuego}
                      arrancarJuego={this.props.arrancarJuego}
                      login={this.props.login}
                      setSaldo={this.props.setSaldo}
                      estadoPlataforma={this.props.estadoPlataforma}
                    />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />

              <Route
                path="/home"
                render={(props) =>
                  this.props.estadoPlataforma.logueado ? (
                    <Home
                      requiereCambio={this.props.requiereCambio}
                      logCurrentPage={this.props.logCurrentPage}
                      currentPage={this.state.currentPage}
                      agreeTerm={this.props.agreeTerm}
                      acceptedTyc={this.props.estadoPlataforma.acceptedTyc}
                      autoexcluidoHasta={
                        this.props.estadoPlataforma.autoexcluidoHasta
                      }
                      notificacionMensajes={this.props.notificacionMensajes}
                      actuVersionSiCorresponde={
                        this.props.actuVersionSiCorresponde
                      }
                      requirioLoginJuego={this.props.requirioLoginJuego}
                      arrancarJuego={this.props.arrancarJuego}
                      estadoPlataforma={this.props.estadoPlataforma}
                      mensajeErrorGeneral={this.props.mensajeErrorGeneral}
                      mensajeErrorWS={this.props.mensajeErrorWS}
                      cerrarSesion={this.props.cerrarSesion}
                      juegoActual={this.props.juegoActual}
                      setJuegoActual={this.props.setJuegoActual}
                      volverAPlataforma={this.props.volverAPlataforma}
                      handleRemember={this.handleRemember}
                    />
                  ) : (
                    <Redirect to="/" />
                  )
                }
              />

              <Route
                path="/"
                exact
                render={(props) => (
                  <Login
                    notificacionMensajes={this.props.notificacionMensajes}
                    actuVersionSiCorresponde={
                      this.props.actuVersionSiCorresponde
                    }
                    arrancarJuego={this.props.arrancarJuego}
                    requirioLoginElLogin={this.props.requirioLoginElLogin}
                    login={this.props.login}
                    setCambio={this.props.setCambio}
                    setSaldo={this.props.setSaldo}
                    estadoPlataforma={this.props.estadoPlataforma}
                    mensajeErrorGeneral={this.props.mensajeErrorGeneral}
                    mensajeErrorWS={this.props.mensajeErrorWS}
                    cerrarSesion={this.props.cerrarSesion}
                    logCurrentPage={this.props.logCurrentPage}
                    currentPage={this.state.currentPage}
                    handleRemember={this.handleRemember}
                  />
                )}
              />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

ReactDOM.render(
  <ContenedorPlataforma />,
  document.getElementById("contenderJuegoTombola")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();
